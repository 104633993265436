import uniqid from 'uniqid'

import './ProjectContainer.css'

const ProjectContainer = ({ project }) => (
  <div className='project'>
    <h3>{project.name}</h3>

    <p className='project__description'>{project.description}</p>
    {project.stack && (
      <>
      {project.logo ? (
        <ul className='project__stack'>
          {project.stack.map((item) => (
            <li key={uniqid()} className='project__stack-icon'>
              <i className={item}/>
            </li>
          ))}
        </ul>
      ):(
        <ul className='project__stack'>
          {project.stack.map((item) => (
            <li key={uniqid()} className='project__stack-item'>
             {item}
            </li>
          ))}
        </ul>
      )}    
      </> 
    )}
  </div>
)

export default ProjectContainer
