import uniqid from 'uniqid'
import { projects, other, gestion } from '../../portfolio'
import ProjectContainer from '../ProjectContainer/ProjectContainer'
import './Projects.css'

const Projects = () => {
  if (!projects.length) return null

  return (
    <>
    <section id='projects' className='section projects'>
      <h2 className='section__title'>Projet professionel 🧰</h2>

      <div className='projects__grid'>
        {projects.map((project) => (
          <ProjectContainer key={uniqid()} project={project} />
        ))}
      </div>
    </section>

    <section id='projects' className='section projects'>
    <h2 className='section__title'>Projet personnel 💻</h2>
            
    <div className='projects__grid'>
      {other.map((project) => (
        <ProjectContainer key={uniqid()} project={project} />
      ))}
    </div>
    </section>

    <section id='projects' className='section projects'>
    <h2 className='section__title'>Gestion de projet 📆</h2>
            
    <div className='projects__grid'>
      {gestion.map((project) => (
        <ProjectContainer key={uniqid()} project={project} />
      ))}
    </div>
    </section>
    </>
  )
}

export default Projects
