const header = {
  // all the properties are optional - can be left empty or deleted
  homepage: 'https://axellbr-portfolio.com',
  title: 'LBRM.',
}

const about = {
  // all the properties are optional - can be left empty or deleted
  firstName: 'xel',
  name: 'iberman',
  role: 'Développeur full stack',
  description:
    'Après mon BTS SIO, j\'ai intégré l\'Institut G4 où j\'ai eu l\'opportunité de faire 3 ans d\'alternance. J\'ai obtenu mon master 2 en gestion de projet digital, ce qui m\'a permis d\'acquérir des compétences en développement ainsi qu\'en gestion de projet.',
  resume: 'Cv_Axel_Liberman_2022_S.pdf',
  social: {
    linkedin: 'https://www.linkedin.com/in/axel-liberman-087100183',
   // github: 'https://github.com',
  },
}

const projects = [
  // projects can be added an removed
  // if there are no projects, Projects section won't show up
  {
    name: 'CRM',
    description:
      'Création d\'une PWA où j\'ai réalisé le projet de la conception jusqu\'à la mise en production',
    logo:true,
    stack: ['fab fa-php', 'fab fa-js-square', 'fab fa-docker', 'fab fa-react'],
  },
  {
    name: 'Automate/API',
    description:
      'Production d\'un robot permettant le transfert et la récupération automatisés entre un ERP et une API SalesForce',
    logo:false,
    stack: ['VB.net', 'PASCAL', 'SQL'],
  },
  {
    name: 'Traitement PDF',
    description:
      'Analyse d\'un document PDF puis redécoupage en fonction du traitement textuel',
    logo:true,
    stack: ['fab fa-python'],
  },
]

const other = [
  // projects can be added an removed
  // if there are no projects, Projects section won't show up
  {
    name: 'Site vitrine & Backoffice',
    description:
      'Refonte d\'un site d\'entreprise et création d\'un backoffice permettant la personnalisation du site',
      logo:true,
    stack: ['fab fa-php', 'fab fa-js-square', 'fas fa-seedling'],
  },
  {
    name: 'Site vitrine & Backoffice',
    description:
      'Création d\'un site personnalisable présentant des annonces de biens immobiliers connecté à une application de réservation',
      logo:true,
    stack: ['fab fa-php', 'fab fa-js-square', 'fas fa-seedling'],
  },
]

const gestion = [
  // projects can be added an removed
  // if there are no projects, Projects section won't show up
  {
    name: 'Chef de projet',
    description:
      'Gestion d\'une équipe de 13 personnes sur un an pour la production d\'un site, permettant la réservation d\'activités de loisirs ainsi que l\'utilisation d\'un "mini" ERP (gestion de stock, gestion des réservations..)',
      logo:false,
    stack: ['Agile', 'Dashboard', 'Réunion', 'Commerce' ],
  },
  {
    name: 'Directeur financier',
    description:
      'Gestion des coûts "projet" d\'une équipe de 80 personnes sur un an, avec un budget projet de 326 000€ pour produire une application web ayant un CRM, un chat, un backoffice, un "mini" réseau social et une partie vitrine',
      logo:false,
    stack: ['Cycle en V', 'Dashboard', 'Automatisation', 'Analyse' ],
  },
]

const skills = [
  // skills can be added or removed
  // if there are no skills, Skills section won't show up
  'HTML',
  'CSS',
  'JavaScript',
  'PHP',
  'React',
  'Python',
  'C#',
  'Unity',
  'Git',
  'Docker',
  'Pascal',
  'SQL/PL SQL',
  'Agile',
  'Cycle en V',
  'Google workspace'
]

const contact = {
  // email is optional - if left empty Contact section won't show up
  email: 'axellbrm@gmail.com',
}

export { header, about, projects, other, gestion, skills, contact }
