import GitHubIcon from '@material-ui/icons/GitHub'
import LinkedInIcon from '@material-ui/icons/LinkedIn'
import './About.css'
import Fade from 'react-reveal/Fade';
import { about } from '../../portfolio'


const About = () => {
  const { firstName, name, role, description, resume, social } = about

  return (
    <div className='about center'>
      {name && (
        <h2 className='inheritFade'>
          <span className="wave">👋</span>
          <Fade left cascade>
           A<span className='about__name'>{firstName}</span> L<span className='about__name'>{name}</span>
          </Fade>
        </h2>
      )}

      {role && <h2 className='about__role'>{role}.</h2>}
      <p className='about__desc'>{description && description}</p>

      <div className='about__contact center'>
        {resume && (
          <a href={resume} download='Cv_Axel_LIBERMAN_2022.pdf'>
            <span type='button' className='btn btn--outline'>
            curriculum vitae
            </span>
          </a>
        )}

        {social && (
          <>
            {social.github && (
              <a
                href={social.github}
                aria-label='github'
                className='link link--icon'
              >
                <GitHubIcon />
              </a>
            )}

            {social.linkedin && (
              <a
                href={social.linkedin}
                aria-label='linkedin'
                className='link link--icon'
              >
                <LinkedInIcon />
              </a>
            )}
          </>
        )}
      </div>
    </div>
  )
}

export default About
